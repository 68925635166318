


































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LETTER_TEMPLATE_LIST, SET_LETTER_TEMPLATE_LIST_PAGE } from '../store';
import { ApiResponse, Pagination } from '@/components/types';
import { LetterTemplate, LetterTemplateList } from '../types';
import { PaginatableMixin } from '@/views/mixin';
import ErrorAlert from '@/components/ErrorAlert.vue';
import CreateLetterTemplateDialog from '../components/CreateLetterTemplateDialog.vue';
import UpdateLetterTemplateDialog from '../components/UpdateLetterTemplateDialog.vue';
import DeleteLetterTemplateDialog from '../components/DeleteLetterTemplateDialog.vue';
import AppTable from '@/components/AppTable.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    AppTable,
    DeleteLetterTemplateDialog,
    UpdateLetterTemplateDialog,
    CreateLetterTemplateDialog,
    ErrorAlert
  }
})
export default class LetterTemplates extends PaginatableMixin {
  @Correspondence.State('letterTemplates') list!: LetterTemplateList;
  @Correspondence.Action(FETCH_LETTER_TEMPLATE_LIST) fetch!: () => Promise<ApiResponse>;
  @Correspondence.Mutation(SET_LETTER_TEMPLATE_LIST_PAGE) setPage!: (page: number) => void;

  error = null;
  loading: boolean = false;

  updateTemplate: LetterTemplate | null = null;
  deleteTemplate: LetterTemplate | null = null;

  pagination: Pagination = {
    sortBy: ['created'],
    itemsPerPage: 10,
    page: 1,
    descending: true
  };

  get items(): LetterTemplate[] {
    return this.list.items;
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name' },
      { align: 'left', text: this.$i18n.t('common.visibility'), value: 'visibility' },
      { align: 'left', text: this.$i18n.t('common.creator'), value: 'creator.name' },
      ...(this.$vuetify.breakpoint.mdAndUp ? [{
        align: 'left',
        text: this.$i18n.t('common.actions'),
        value: null
      }] : [])
    ];
  }

  async created() {
    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
