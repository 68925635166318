




















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UPDATE_LETTER_TEMPLATE } from '../store';
import RichEditor from './RichEditor.vue';
import { letterPlaceholder, Placeholder } from '@/statics/placeholders';
import PlaceholderTable from './PlaceholderTable.vue';
import ReceiverTypeSelect from './ReceiverTypeSelect.vue';
import VisibilitySelect from './VisibilitySelect.vue';
import { LetterTemplate, WriteLetterTemplate } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateLetterTemplateToWriteLetterTemplate } from '@/modules/correspondence/model';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    PlaceholderTable,
    RichEditor,
    VisibilitySelect,
    ReceiverTypeSelect
  }
})
export default class UpdateLetterTemplateDialog extends Vue {
  @Prop({ type: Object }) letterTemplate?: LetterTemplate;

  @Correspondence.Action(UPDATE_LETTER_TEMPLATE) updateTemplate!: (letterTemplate: WriteLetterTemplate) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: WriteLetterTemplate | null = null;

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:letterTemplate', null);
      observer && observer.reset();
    }, 200);
  }

  @Watch('letterTemplate')
  watchOutbox(letterTemplate: LetterTemplate) {
    if (!letterTemplate) return;

    this.value = MapStateLetterTemplateToWriteLetterTemplate(letterTemplate);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  get placeholders(): Placeholder[] {
    if (!this.value) return [];

    return letterPlaceholder(
      this.value.requirements.includes('conservatorship'),
      this.value.requirements.includes('bank_account')
    );
  }

  async save() {
    this.error = null;

    if (!this.value) return;

    return this.updateTemplate(this.value);
  }
}
